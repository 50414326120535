<template>
  <q-toolbar class="full-height q-px-none">
    <q-btn
      v-if="!onlyLocale"
      round
      outline
      icon="menu"
      color="muted"
      aria-label="Menu"
      class="lt-sm"
      @click="toggleLeftDrawer"
    />

    <router-link :to="onlyLocale ? '' : '/'" class="flex items-center q-pl-md">
      <q-img
        src="~assets/img/logo/logo-color.png"
        alt="WeTransform"
        width="45px"
        fit="contain"
      />
      <div
        class="text-weTransform text-gradient q-ml-sm gt-xs"
        style="font-size: 20px"
      >
        WeTransform
      </div>
    </router-link>
    <q-chip color="grey-4" class="q-ml-md"> Beta </q-chip>

    <q-space v-if="environement === 'dev'" />

    <q-chip
      v-if="environement === 'dev'"
      color="negative"
      size="xl"
      class="animated pulse infinite slower gt-sm"
    >
      {{ environement }}
    </q-chip>

    <q-space />

    <q-btn
      v-if="userStore.user?.impersonator"
      :loading="loading"
      :label="$t('common.logBack')"
      outline
      color="primary"
      @click="logBack"
    />

    <!-- ADMIN SETTINGS -->
    <admin-menu
      v-if="
        userStore.user?.organisations_user_belongs_to &&
        !onlyLocale &&
        userStore.isAdmin
      "
      :link-actions="adminLinks"
      :check-roles="checkRoles"
    />
    <!-- TEAM SWITCHER -->
    <div
      v-if="
        userStore.user?.organisations_user_belongs_to &&
        !onlyLocale &&
        userStore.activeOrganisation
      "
      class="q-mx-sm gt-xs"
    >
      <organisation-switcher
        v-model:menu="orgaStore.organisationMenu"
        :link-actions="organisationLinks"
        :organisations="userStore.organisations"
        :active-organisation="userStore.activeOrganisation"
        :switch-organisation="switchOrganisation"
        :check-roles="checkRoles"
      />
    </div>
    <!-- LANG SWITCHER -->
    <lang-switcher class="q-mx-sm" @change-locale="handleLocaleChange" />
    <!-- NOTIFICATIONS -->
    <notifications-btn
      v-if="userStore.user?.organisations_user_belongs_to && !onlyLocale"
      v-model:new-notif="notifStore.newNotif"
      v-model:notif-count="notifStore.notifCount"
      :last-notifications="notifStore.lastNotifications"
      :notifications-action="notificationsAction"
      :fetch-last-notifications="notifStore.fetchLastNotifications"
      :remove-notification="notifStore.removeNotification"
      :see-all-link="{ to: '/account/notifications' }"
    />
    <!-- ACCOUNT -->
    <account-menu
      v-if="userStore.user?.organisations_user_belongs_to && !onlyLocale"
      :link-actions="accountLinks"
      :on-logout="handleLogout"
      :user-identification="{
        pictureUrl: userStore.user.profile.picture_url,
        firstname: userStore.user.profile.firstname,
        lastname: userStore.user.profile.lastname,
        email: userStore.user.email,
      }"
    />

    <q-btn
      v-else-if="!onlyLocale"
      to="/login"
      :label="$t('router.login')"
      color="primary"
      outline
      class="outline-reverse"
      style="min-width: 120px"
    />

    <q-btn v-if="onlyLocale" color="muted" outline round @click="handleLogout">
      <q-icon name="fa-solid fa-arrow-right-from-bracket" size="16px" />
      <q-tooltip>
        {{ $t('common.signOut') }}
      </q-tooltip>
    </q-btn>
  </q-toolbar>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useUserStore } from 'stores/receiver/account/user/user'
import { usePreferenceStore } from 'stores/receiver/account/user/preference'
import { useAuthStore } from 'stores/receiver/account/auth/auth'
import { useNotificationsStore } from 'stores/receiver/account/user/notifications'
import { useOrganisationsStore } from 'stores/receiver/account/organisations/organisations'

import { useDrawer } from 'composables/common/layout/useDrawer'
import { accountLinks } from 'composables/receiver/account/accountLinks'
import { adminLinks } from 'composables/receiver/admin/adminLinks'
import { useCheckRoles } from 'composables/receiver/user/useCheckRoles'
import { useOrganisationChange } from 'composables/receiver/user/useOrganisationChange'
import { organisationLinks } from 'composables/receiver/account/accountLinks'
import { useImpersonate } from 'composables/receiver/user/useImpersonate'
import { useNotifications } from 'composables/receiver/account/useNotifications'

import OrganisationSwitcher from 'components/common/layout/organisation/OrganisationSwitcher.vue'
import NotificationsBtn from 'components/common/layout/NotificationsBtn.vue'
import AdminMenu from 'components/common/layout/AdminMenu.vue'
import AccountMenu from 'components/common/layout/AccountMenu.vue'
import LangSwitcher from 'components/common/layout/LangSwitcher.vue'

import { LocalesCode } from 'src/i18n/locales'

// PROPS
defineProps<{
  onlyLocale?: boolean
}>()

const userStore = useUserStore()
const preferenceStore = usePreferenceStore()
const notifStore = useNotificationsStore()
const { checkRoles } = useCheckRoles()

const router = useRouter()

// Update receiver locale preferences if needed
const handleLocaleChange = (code: LocalesCode) => {
  if (userStore.isLoggedIn) {
    preferenceStore.updatePreferences({
      language: code,
    })
  }
}

const { toggleLeftDrawer } = useDrawer()

const environement = process.env.INFRA_ENV

// Impersonator
const loading = ref(false)
const logBack = async () => {
  const { logback } = useImpersonate()
  loading.value = true

  await logback()

  loading.value = false
}

// Notifications
const { notificationsAction } = useNotifications()

// Organisation switcher
const { switchOrganisation } = useOrganisationChange()
const orgaStore = useOrganisationsStore()

// Logout
const handleLogout = async () => {
  await useAuthStore().signOut()
  router.push('/login')
}
</script>
