import { computed } from 'vue'
import { extend } from 'quasar'

import { BaseLinks, LinkActions } from 'types/common/layout/layout'

/**
 * Custom hook for generating a common menu based on the provided base menu and link actions.
 * @template K - links IDs.
 * @param {BaseLinks<K>[]} baseMenu - The base menu containing the links.
 * @param {LinkActions<K>} linkActions - The link actions object containing actions for each link.
 * @param {((roles: string[], all?: boolean) => boolean)?} checkRoles - Optional function to check user roles for link access.
 * @returns {{ menuLinks: ComputedRef<BaseLinks<K>[]> }} - The computed menu links.
 */
export const useCommonMenu = <K extends string>(
  baseMenu: BaseLinks<K>[],
  linkActions: LinkActions<K>,
  checkRoles?: (roles: string[], all?: boolean) => boolean
) => {
  const menuLinks = computed(() => {
    return getActiveLinks().map((link) => {
      return {
        ...link,
        ...linkActions[link.id],
      }
    })
  })

  function getActiveLinks() {
    const clone = baseMenu.map((el) => {
      return extend<BaseLinks<K>>(true, {}, el)
    })

    return clone.filter((el) => {
      if (checkRoles) {
        return el.access
          ? checkRoles(el.access)
          : el.oneOfAccess
            ? checkRoles(el.oneOfAccess, false)
            : linkActions[el.id].display
              ? linkActions[el.id].display?.()
              : true
      } else {
        return linkActions[el.id].display
          ? linkActions[el.id].display?.()
          : true
      }
    })
  }

  return { menuLinks }
}
