<template>
  <div>
    <component :is="flagIcon" />
  </div>
</template>

<script setup lang="ts">
// Created a lightweight component to wrap the circle flags icons
// Based on https://github.com/HatScripts/circle-flags

import { defineAsyncComponent } from 'vue'

import { TCountryCodes } from './flagTypes'

const props = defineProps<{
  country: TCountryCodes
}>()

const flagIcon = defineAsyncComponent(
  () => import(`./flags/${props.country}-flag.vue`)
)
</script>
