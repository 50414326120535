<template>
  <q-select
    :model-value="activeOrganisation"
    :outlined="false"
    borderless
    :options="organisations.length > 1 ? organisations : []"
    option-value="organisation_id"
    option-label="name"
    bg-color="white"
    class="q-pl-md ellipsis"
    style="max-width: 220px"
    :menu-offset="[16, 15]"
    menu-anchor="bottom left"
    menu-self="top left"
    popup-content-class="q-pa-lg"
    :hide-dropdown-icon="organisations.length <= 1"
    @update:model-value="handleSwitch"
  >
    <template #option="scope">
      <q-item
        v-bind="scope.itemProps"
        dense
        class="item-rounded"
        data-test="orga"
        @click="onOrganisationClick(scope.opt.organisation_id)"
      >
        <q-item-section side>
          <q-avatar size="21px" class="bg-grey-2">
            <q-img
              v-if="scope.opt.logo"
              :src="scope.opt.logo"
              :alt="scope.opt.name"
              spinner-color="white"
              class="fit"
            />
            <q-icon
              v-else
              name="fa-solid fa-building"
              color="grey-5"
              class="fit"
            />
          </q-avatar>
        </q-item-section>
        <q-item-section class="flex items-center">
          <q-item-label> {{ scope.opt.name }} </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            v-if="
              scope.opt.active &&
              checkRoles(
                [
                  'ORGANISATION_UPDATE_SETTINGS',
                  'ORGANISATION_MEMBERS',
                  'ORGANISATION_ROLES',
                ],
                false
              )
            "
            color="grey-5"
            flat
            round
            icon="fa-solid fa-gear"
            size="xs"
            data-test="settings"
            @click="menu = true"
          />
        </q-item-section>
      </q-item>

      <q-separator v-if="scope.index !== organisations.length - 1" spaced />
    </template>
  </q-select>

  <organisation-menu
    v-if="activeOrganisation"
    v-model:dialog="menu"
    :organisation="activeOrganisation"
    :link-actions="linkActions"
    :check-roles="checkRoles"
  />
</template>

<script setup lang="ts">
import OrganisationMenu, {
  LinkIds,
} from 'components/common/layout/organisation/OrganisationMenu.vue'

import type { LinkActions, OrganisationLight } from 'types/common/layout/layout'

// PROPS
const props = defineProps<{
  organisations: OrganisationLight[]
  activeOrganisation: OrganisationLight
  switchOrganisation: (id: string) => Promise<boolean>
  linkActions: LinkActions<LinkIds>
  checkRoles: (roles: string[], all?: boolean) => boolean
}>()
// MODELS
const menu = defineModel<boolean>('menu', { required: true })

const handleSwitch = (val: OrganisationLight) => {
  props.switchOrganisation(val.organisation_id)
}

const onOrganisationClick = (orgaId: string) => {
  if (
    orgaId === props.activeOrganisation.organisation_id &&
    props.checkRoles(
      [
        'ORGANISATION_UPDATE_SETTINGS',
        'ORGANISATION_MEMBERS',
        'ORGANISATION_ROLES',
      ],
      false
    )
  ) {
    menu.value = true
  }
}
</script>

<style lang="scss" scoped>
:deep(.q-field__native) {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
