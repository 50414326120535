import { ref } from 'vue'
import { Screen } from 'quasar'

const leftDrawerOpen = ref(false)
const miniState = ref(false)

export const useDrawer = () => {
  const toggleLeftDrawer = () => {
    // For mobile open the drawer
    if (Screen.lt.lg) {
      leftDrawerOpen.value = !leftDrawerOpen.value
      // For desktop expand the mini drawer
    } else {
      miniState.value = !miniState.value
    }
  }

  return { leftDrawerOpen, miniState, toggleLeftDrawer }
}
