export const adminLinks = {
  users: {
    to: '/admin/users',
  },
  roles: {
    to: '/admin/roles',
  },
  services: {
    to: '/admin/billing/services',
  },
  allowance: {
    to: '/admin/billing/allowance',
  },
  purchases: {
    to: '/admin/purchases',
  },
  function: {
    to: '/admin/functions',
  },
  impersonate: {
    to: '/admin/impersonate',
  },
  'import-export': {
    to: '/admin/import-export/functions',
  },
}
