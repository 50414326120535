<template>
  <q-btn
    class="q-mx-sm"
    :color="menu ? 'primary' : 'muted'"
    outline
    round
    @click="handleNotifMenu"
  >
    <q-icon name="fa-solid fa-bell" size="16px" />
    <q-tooltip>
      {{ $t('notifications.title') }}
    </q-tooltip>
    <transition
      appear
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <q-badge v-if="newNotif" :label="newNotif" color="red" rounded floating />
    </transition>
    <!-- Notification Menu -->
    <q-menu
      v-model="menu"
      anchor="bottom right"
      self="top right"
      :offset="[0, 24]"
      max-height="80vh"
    >
      <div class="q-py-lg">
        <div class="row q-px-lg q-mb-md">
          <div class="col">
            <h4 class="q-my-none">
              {{ $t('notifications.title') }}
            </h4>
          </div>
          <div class="col-auto">
            <q-btn
              v-if="notifCount"
              :to="seeAllLink.to"
              :href="seeAllLink.href"
              :disable="loading"
              flat
              dense
              color="primary"
              class="text-underline"
              :label="$t('notifications.seeAll')"
            />
          </div>
        </div>
        <!-- Notifications -->
        <template v-if="notifCount && !loading">
          <template v-for="notifObj in notifDisplayed" :key="notifObj.id">
            <p class="text-caption text-muted q-ml-lg q-mt-md">
              {{ notifObj.title() }}
            </p>
            <q-list>
              <notification-card
                v-for="notif in notifObj.notifs"
                :key="`notif-${notif.id}card`"
                v-model:notif-count="notifCount"
                :notif="notif"
                :notifications-action="notificationsAction"
                :remove-notification="removeNotification"
                :dense="true"
                style="max-width: 500px"
              />
            </q-list>
          </template>
        </template>
        <!-- Loading -->
        <template v-if="loading">
          <notification-skeleton :loader-count="loaderCount" />
        </template>
        <!-- Error -->
        <div v-else-if="error" class="text-center q-my-xl q-px-xl">
          <p style="min-width: max-content">
            <q-icon name="fa-regular fa-face-sad-tear" left />
            {{ $t('notifications.error') }}
          </p>
        </div>
        <!-- No notifs -->
        <div v-else-if="!notifCount" class="text-center q-my-xl q-px-xl">
          <p class="text-muted q-mb-md">
            {{ $t('notifications.noNotif') }}
          </p>
          <svg-img image="empty" width="120px" />
        </div>
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { date } from 'quasar'

import { useEventsStore } from 'stores/common/events'

import NotificationCard from 'components/common/ui/notification/NotificationCard.vue'
import NotificationSkeleton from 'components/common/ui/notification/NotificationSkeleton.vue'
import SvgImg from 'components/common/ui/svgImage/SvgImg.vue'

import { NotifType, NotificationTypes } from 'types/common/notifications'
import { ExactlyOneKey } from 'types/common/commonTypes'

const { getDateDiff, getWeekOfYear } = date
const { t } = useI18n()

// PROPS
const props = defineProps<{
  lastNotifications: null | NotifType[]
  fetchLastNotifications: () => Promise<boolean>
  removeNotification: (id: string) => Promise<boolean>
  notificationsAction: Record<NotificationTypes, () => void>
  seeAllLink: ExactlyOneKey<'to' | 'href', string>
}>()
// MODELS
const notifCount = defineModel<number>('notifCount', {
  required: true,
})
const newNotif = defineModel<number>('newNotif', {
  required: true,
})

const menu = ref(false)
const error = ref(false)

const loading = ref(false)

const loaderCount = computed(() =>
  notifCount.value <= 5 ? notifCount.value : 5
)

// Filter notification to different arrays depending on date (today, yesterday, last week, later)
const fitlerNotifs = computed(() => {
  const notifs: { id: number; title: () => string; notifs: NotifType[] }[] = [
    {
      id: 0,
      title: () => t('common.date.today'),
      notifs: [],
    },
    {
      id: 1,
      title: () => t('common.date.yesterday'),
      notifs: [],
    },
    {
      id: 2,
      title: () => t('common.date.laterThisWeek'),
      notifs: [],
    },
    {
      id: 3,
      title: () => t('common.date.lastWeek'),
      notifs: [],
    },
    {
      id: 4,
      title: () => t('common.date.later'),
      notifs: [],
    },
  ]
  // Filter
  props.lastNotifications?.forEach((notif) => {
    const dayDiff = getDateDiff(notif.notified_at, new Date(), 'days')
    const thisWeek = getWeekOfYear(new Date())
    const notifWeek = getWeekOfYear(new Date(notif.notified_at))

    if (dayDiff === 0) {
      notifs[0].notifs.push(notif)
    } else if (dayDiff === -1) {
      notifs[1].notifs.push(notif)
    } else if (thisWeek === notifWeek) {
      notifs[2].notifs.push(notif)
    } else if (thisWeek - notifWeek === 1) {
      notifs[3].notifs.push(notif)
    } else {
      notifs[4].notifs.push(notif)
    }
  })
  return notifs || []
})

const notifDisplayed = computed(() => {
  return fitlerNotifs.value.filter((el) => el.notifs.length)
})

// Fetch notifs
const handleNotifMenu = async () => {
  if (notifCount.value && !menu.value) {
    loading.value = true

    const success = await props.fetchLastNotifications()

    if (!success) {
      error.value = true
    }

    loading.value = false
  }
}

// Update newNotif and notifCount
const eventsStore = useEventsStore()

watch(
  () => eventsStore.eventData,
  async (val) => {
    if (val?.type === 'notification' || val?.type === 'notification_removal') {
      newNotif.value = val.context.new
      notifCount.value = val.context.total
    }
  }
)
</script>
